<form class="flex flex-column" [formGroup]="form">
  <div class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="name">Parameter Name</label>
    <input
      class="w-20rem"
      id="name"
      type="text"
      formControlName="name"
      pInputText
      autocomplete="off"
      placeholder="variableName"
    />
  </div>
  <div class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="query">Query</label>
    <input
      class="w-20rem"
      id="query"
      type="text"
      formControlName="query"
      pInputText
      autocomplete="off"
      placeholder="select * from df"
    />
  </div>
  <div class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="notEmpty">Not Empty</label>
    <p-checkbox
      class="mr-2 block w-2"
      id="notEmpty"
      [binary]="true"
      formControlName="notEmpty"
      inputId="notEmpty"
    ></p-checkbox>
  </div>
  <div class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="isArray">Is Array</label>
    <p-checkbox
      class="mr-2 block w-2"
      id="isArray"
      [binary]="true"
      formControlName="isArray"
      inputId="isArray"
    ></p-checkbox>
  </div>
</form>
