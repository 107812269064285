import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, debounceTime, delay, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageIsReadyService {
  private counterRequests$ = new BehaviorSubject<number>(0);

  private checkCounterRequests$: Observable<boolean> = this.counterRequests$
    .asObservable()
    .pipe(map((counter) => counter === 0));

  private bodyObserver$: Observable<true> = this.observeMutations(document.body, {
    childList: true,
    subtree: true,
  }).pipe(map(() => true));

  pageIsReady$: Observable<boolean> = combineLatest({
    counterRequests: this.checkCounterRequests$.pipe(delay(1000), debounceTime(300)),
    mutationObserver: this.bodyObserver$.pipe(debounceTime(1000)),
  }).pipe(map(({ counterRequests, mutationObserver }) => counterRequests && mutationObserver));

  stateIsLoading$: Observable<boolean> = combineLatest({
    counterRequests: this.checkCounterRequests$,
  }).pipe(map(({ counterRequests }) => !counterRequests));

  increaseCounterRequests(): void {
    this.counterRequests$.next(this.counterRequests$.value + 1);
  }

  dicreaseCounterRequests(): void {
    this.counterRequests$.next(this.counterRequests$.value - 1);
  }

  private observeMutations(element: Node, config: MutationObserverInit): Observable<MutationRecord[]> {
    return new Observable((subscriber) => {
      const observer = new MutationObserver((mutations) => {
        subscriber.next(mutations);
      });

      observer.observe(element, config);

      return () => {
        observer.disconnect();
      };
    });
  }
}
