import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HistoryBackService } from '@selfai-platform/shared';
import { Observable, of } from 'rxjs';
import { WorkflowPresetsComponent } from '../../components/workflow-presets/workflow-presets.component';
import { getPresetsPath } from '../../functions';
import { WorkflowPresets } from '../../interfaces/workflow-presets.interface';
import { WorkflowPresetsDomainService } from '../../services/workflow-presets-domain.service';

const DEFAULT_EXECUTOR_CORES = 2;
const DEFAULT_NUMB_OF_EXECUTORS = 2;

@Component({
  selector: 'selfai-platform-workflow-presets-container',
  standalone: true,
  imports: [CommonModule, WorkflowPresetsComponent],
  templateUrl: './workflow-presets-container.component.html',
  styleUrl: './workflow-presets-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPresetsContainerComponent implements OnInit {
  workflowDefaultPresets: WorkflowPresets = {
    clusterType: 'local',
    driverMemory: '1G',
    executorCores: DEFAULT_EXECUTOR_CORES,
    executorMemory: '2G',
    hadoopUser: null,
    id: '1',
    isDefault: false,
    isEditable: true,
    name: 'default',
    numExecutors: DEFAULT_NUMB_OF_EXECUTORS,
    params: '',
    totalExecutorCores: DEFAULT_EXECUTOR_CORES * DEFAULT_NUMB_OF_EXECUTORS,
    uri: 'local[*]',
    userIP: '',
  };
  loading$!: Observable<boolean>;
  id: string = this.activatedRoute?.snapshot?.params['id'];
  editMode = !!this.id;

  workflowPresets$: Observable<WorkflowPresets> = this.editMode
    ? this.workflowPresetsDomainService.getItem(this.id)
    : of(this.workflowDefaultPresets);

  constructor(
    private activatedRoute: ActivatedRoute,
    private history: HistoryBackService,
    private workflowPresetsDomainService: WorkflowPresetsDomainService,
  ) {}

  ngOnInit(): void {
    if (this.editMode) {
      this.workflowPresetsDomainService.loadItem(this.id);
    }
  }

  onSubmit(data: WorkflowPresets): void {
    if (this.editMode) {
      this.workflowPresetsDomainService.update(data);
    } else {
      this.workflowPresetsDomainService.add(data);
    }
  }

  onBack(): void {
    this.history.back(getPresetsPath());
  }
}
