import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AdditionalParamFormGroup } from '@selfai-platform/pipeline-common';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'selfai-platform-additional-param-edit',
  templateUrl: './additional-param-edit.component.html',
  styleUrls: ['./additional-param-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CheckboxModule,
    InputTextModule,
  ],
})
export class AdditionalParamEditComponent {
  @Input() form: FormGroup<AdditionalParamFormGroup>;
}
