import { IndexableType } from 'dexie';
// import { AggregationType } from '@selfai-platform/bi-domain';

export interface AiBotApiVersionResponseDTO {
  version: string;
}

export interface IAIModel {
  model: string;
  name: string;
}

export interface AiBotApiTagsResponseDTO {
  models: IAIModel[];
}

export interface AiBotApiChatRequestDTO {
  model: string;
  messages: IApiMessage[];
  options?: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
}

export interface AiBotApiChatResponseDTO {
  model: string;
  message: IApiMessage;
}

export interface AiBotApiChatCompletionsRequestDTO extends AiBotApiChatRequestDTO {}

export type AiBotApiChatUrlsResponseDTO = Record<string, string[]>;

export interface IApiMessage {
  role: EAIChatRole;
  content: string;
  frontendAction?: IAiFrontendAction;
}

export enum EAIChatRole {
  USER = 'user',
  ASSISTANT = 'assistant',
  TOOL_CALL_RESULT = 'tool_call_result',
}

export interface IAiFrontendAction {
  name: EFrontendActions;
  params: unknown;
}

export enum EFrontendActions {
  NEW_CHART_PREVIEW = 'new_chart_preview',
}

export interface IChartPreviewData {
  chartType?: string;
  dimension?: string | string[];
  measure?: string | string[];
  datasourceId?: string;
  measureAggregation?: any | any[]; // AggregationType | AggregationType[];
  textWidgetContent?: string;
}

export interface IChat {
  id?: string | number | IndexableType;
  title?: string;
  messages: IChatMessage[];
  createdAt: Date;
  updatedAt: Date;
  isLoadingMessage?: boolean;
}

export interface IChatMessage {
  id?: string | number;
  content: string;
  role: EAIChatRole;
  createdAt: string;
  username: string;
  type?: string;
}

export enum EAIMessageType {
  SYSTEM = 'SYSTEM',
  COMMON = 'COMMON',
}
