import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateTriggerData,
  CreateTriggerFormGroup,
  CubeEditResult,
  CubeWorkflowData,
  TriggerRule,
  TriggerRuleFormGroup,
  WorkflowTriggerData,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { BehaviorSubject, Observable, distinctUntilChanged, filter, map } from 'rxjs';
import {
  extractSelectionItemsCreateTrigger,
  normalizeDataCreateTrigger,
} from '../../../converters/create-trigger.normalizer';
import { SelectionStoreService } from '../../../services/selection-store.service';

interface StoreData {
  nodeId: string;
  data: CreateTriggerData;
}

@Injectable()
export class CreateTriggerComponentService extends BehaviorSubject<StoreData> {
  constructor(
    dialogService: DialogService<CubeEditResult, CubeWorkflowData<WorkflowTriggerData>>,
    selectionStoreService: SelectionStoreService,
    private destroyRef: DestroyRef,
  ) {
    let data: StoreData = { data: {} } as StoreData;
    if (dialogService.data) {
      const {
        selectedNode: { id: nodeId, parameters },
      } = dialogService.data;
      if (parameters) {
        data = { nodeId, data: normalizeDataCreateTrigger(parameters.serialize()) };

        const selectionColumns = extractSelectionItemsCreateTrigger(dialogService.data.selectedNode);
        if (selectionColumns) {
          selectionStoreService.setSelectionsColumns(selectionColumns);
        }
      }
    }

    super(data);
  }

  // rest fields to except conditions
  getFormGroup(): FormGroup<CreateTriggerFormGroup> {
    const { data } = this.value;

    return new FormGroup({
      resultColumn: new FormControl(data.resultColumn, [Validators.required]),
      debugMode: new FormControl(data.debugMode),
    });
  }

  getTriggerRulesFormGroups(): Observable<FormGroup<TriggerRuleFormGroup>[]> {
    return this.asObservable().pipe(
      map(({ data: { conditions } }) => conditions),
      filter(Boolean),
      map((conditions) => conditions.map(this.mapItemToFormGroup.bind(this))),
    );
  }

  getNodeId(): Observable<string> {
    return this.asObservable().pipe(map(({ nodeId }) => nodeId));
  }

  mapItemToFormGroup(item: TriggerRule): FormGroup<TriggerRuleFormGroup> {
    const primaryFormControl = new FormControl(item.primary);
    const primaryCloneFormControl = this.cloneSynchronizedControl(primaryFormControl);

    return new FormGroup<TriggerRuleFormGroup>({
      id: new FormControl(item.id) as FormControl<string>,
      attrColumnName: new FormControl(item.attrColumnName),
      condition: new FormControl(item.condition, [Validators.required]),
      primary: primaryFormControl,
      primaryClone: primaryCloneFormControl,
      resultColumn: new FormControl(item.resultColumn),
      value: new FormControl(item.value),
      ignoreCase: new FormControl(item.ignoreCase || false),
      startPosition: new FormControl(item.startPosition || null),
      startRange: new FormControl(item.startRange || null),
      includeStartRange: new FormControl(item.includeStartRange || null),
      endRange: new FormControl(item.endRange || null),
      includeEndRange: new FormControl(item.includeEndRange || null),
    });
  }

  private cloneSynchronizedControl<T>(formControl: FormControl<T>): FormControl<T> {
    const cloneFormControl = new FormControl<T>(formControl.value);

    formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe((value) => cloneFormControl.setValue(value));

    cloneFormControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe((value) => formControl.setValue(value));

    return cloneFormControl;
  }
}
