<form class="workflow-runner-form flex flex-column" [formGroup]="form">
  <div class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="workflow-runner-workflows">
      {{ 'workflow.cubes.workflow-runner.form.control.selected-workflow' | translate }}
    </label>
    <p-dropdown
      class="w-4"
      id="workflow-runner-workflows"
      [options]="workflowListOptions$ | async"
      formControlName="workflow"
      optionLabel="name"
      placeholder="{{ 'workflow.cubes.workflow-runner.form.control.selected-workflow.placeholder' | translate }}"
      [filter]="true"
      [showClear]="true"
      [loading]="!(workflowListOptions$ | async)"
      (onClear)="clearWorkflowControl()"
      filterBy="name"
    />
  </div>

  <div class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="workflow-runner-workflow-param-to-run">
      {{ 'workflow.cubes.workflow-runner.form.control.workflow-param-to-run' | translate }}
    </label>
    <p-dropdown
      class="w-4"
      id="workflow-runner-workflow-param-to-run"
      [options]="paramToRunOptions"
      formControlName="workflowParamToRun"
      placeholder="{{ 'workflow.cubes.workflow-runner.form.control.workflow-param-to-run.placeholder' | translate }}"
    />
  </div>

  <div  class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="workflowId">
      {{ 'workflow.cubes.workflow-runner.form.control.workflow-id' | translate }}
    </label>
    <input class="w-4" id="workflowId" type="text" formControlName="workflowId" pInputText />
  </div>

  <div  class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="workflowName">
      {{ 'workflow.cubes.workflow-runner.form.control.workflow-name' | translate }}
    </label>
    <input class="w-4" id="workflowName" type="text" formControlName="workflowName" pInputText />
  </div>

  <div class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="stopFlowBeforeRun">
      {{ 'workflow.cubes.workflow-runner.form.control.stop-flow' | translate }}
    </label>
    <p-checkbox
      id="stopFlowBeforeRun"
      name="input"
      [binary]="true"
      formControlName="stopFlowBeforeRun"
      inputId="stopFlowBeforeRun"
    ></p-checkbox>
  </div>

  <div class="mb-2 flex align-items-center">
    <label class="mr-2 block w-2" for="workflow-runner-cluster">
      {{ 'workflow.cubes.workflow-runner.form.control.spark-cluster-config' | translate }}
    </label>

    <p-dropdown
      class="w-4 mr-2"
      id="workflow-runner-cluster"
      [options]="workflowRunnerClusterOptions"
      formControlName="clusterConfig"
      optionLabel="name"
      optionValue="value"
      placeholder="{{ 'workflow.cubes.workflow-runner.form.control.spark-cluster-config.placeholder' | translate }}"
    />

    <div
      *ngIf="form.controls.clusterConfig.value === EClusterConfigurationOptions.Override"
      class="flex align-items-center"
    >
      <p-button
        class="mr-2"
        icon="pi pi-pencil"
        [outlined]="true"
        (click)="op.toggle($event)"
      ></p-button>

      <i class="pi pi-info-circle mr-2"></i>

      <div
        #overlayTarget
        style="position: absolute; top: 0; left: -180px"
      ></div>

      <p-overlayPanel #op [showCloseIcon]="true" [style]="{ width: '460px' }" [appendTo]="overlayTarget">
        <selfai-platform-cluster-configuration-form
          [clusterConfig]="clusterConfig"
          [isShort]="true"
          (backFromForm)="op.hide()"
          (submitForm)="setCustomClusterConfig($event); op.hide()"
        ></selfai-platform-cluster-configuration-form>
      </p-overlayPanel>
    </div>
  </div>
</form>

<p-table
  #tableRef
  [selfaiPlatformCellSizing]="sizingTableName"
  class="table"
  dataKey="controls.id.value"
  [resizableColumns]="true"
  responsiveLayout="scroll"
  styleClass="p-datatable-gridlines"
  [value]="items"
  (onRowReorder)="items = tableRef.value"
>
  <ng-template pTemplate="header">
    <tr #tableColRef>
      <th style="width: 3rem"></th>
      <th pResizableColumn>{{ 'workflow.cubes.workflow-runner.form.control.parameter-name' | translate }}</th>
      <th pResizableColumn>{{ 'workflow.cubes.workflow-runner.form.control.query' | translate }}</th>
      <th pResizableColumn>{{ 'workflow.cubes.workflow-runner.form.control.non-empty' | translate }}</th>
      <th pResizableColumn>{{ 'workflow.cubes.workflow-runner.form.control.is-array' | translate }}</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-expanded="expanded" let-index="rowIndex">
    <tr [pReorderableRow]="index">
      <td>
        <span class="pi pi-bars" pReorderableRowHandle></span>
      </td>
      <td [pRowToggler]="item">
        {{ item.controls.name.value }}
      </td>
      <td [pRowToggler]="item">
        {{ item.controls.query.value }}
      </td>
      <td>
        <p-checkbox [formControl]="item.controls.notEmptyClone" name="notEmpty" [binary]="true"></p-checkbox>
      </td>
      <td>
        <p-checkbox [formControl]="item.controls.isArrayClone" name="isArray" [binary]="true"></p-checkbox>
      </td>
      <td>
        <button
          type="button"
          pButton
          class="p-button-text p-button-link p-button-danger p-0"
          (click)="removeItem(item.controls.id.value)"
          icon="pi pi-trash"
          pTooltip="Remove"
        ></button>
        <button
          type="button"
          pButton
          class="p-button-text p-button-link p-0"
          [icon]="expanded ? 'pi pi-times' : 'pi pi-pencil'"
          [pTooltip]="
            !expanded ?
            ('workflow.cubes.workflow-runner.edit-parameter' | translate) :
            ('workflow.cubes.workflow-runner.close-parameter' | translate)
          "
          [pRowToggler]="item"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-item>
    <tr>
      <td colspan="6">
        <div [@rowExpansionTrigger]="'active'">
          <selfai-platform-additional-param-edit [form]="item"></selfai-platform-additional-param-edit>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6">{{ 'workflow.cubes.workflow-runner.no-data' | translate }}</td>
    </tr>
  </ng-template>
</p-table>

<button
  label="add group"
  pButton
  pRipple
  icon="pi pi-plus"
  type="button"
  class="mr-3 mt-3 p-button-sm"
  (click)="addItem()"
></button>
