<selfai-platform-data-list-view
  [layoutTypes]="['table']"
  [columns]="columns"
  [showEntityIcon]="true"
  [groupActions]="groupActions$ | async"
  [emptyMessage]="'shell.board.filter.ui.search-all.nodata' | translate"
>
  <ng-template selfaiPlatformDataListViewTemplateName="fieldValue.tags" let-item>
    <div class="tags">
      <div class="overflow-x-scroll tags-container">
        <p-tag class="mr-2" *ngFor="let tag of item.tags">
          <div class="flex align-items-center gap-2 px-1">
            <span class="text-base font-normal">{{ tag }}</span>
          </div>
        </p-tag>
      </div>
    </div>
  </ng-template>

  <ng-container slot="toolbarActions">
    <p-multiSelect
      optionLabel="name"
      [placeholder]="'user-functions-list.toolbar.actions.placeholder.filter-tags' | translate"
      [(ngModel)]="selectedTags"
      [options]="tags"
      [showClear]="true"
      (onClear)="onClearTagsFilter()"
      (onChange)="onFilterByTags($event)"
    >
      <ng-template class="select-item" let-tag pTemplate="item">
        <div class="select-item flex align-items-center justify-content-between w-full">
          <div>{{ tag.name }}</div>
          <i class="delete-tag-button pi pi-trash hover" (click)="removeTag(tag); $event.stopPropagation()"></i>
        </div>
      </ng-template>
    </p-multiSelect>

    <p-button
      *ngIf="canCreate"
      class="p-button-success"
      severity="success"
      type="button"
      routerLink="./create"
      icon="pi pi-plus-circle"
      tooltipPosition="top"
      pTooltip="{{ 'user-functions-list.toolbar.actions.create' | translate }}"
    ></p-button>

    <p-button
      type="button"
      icon="pi pi-upload"
      tooltipPosition="top"
      pTooltip="{{ 'user-functions-list.toolbar.actions.import' | translate }}"
      (click)="importUserFunctions()"
    ></p-button>

    <p-button
      type="button"
      icon="pi pi-download"
      tooltipPosition="top"
      pTooltip="{{ 'user-functions-list.toolbar.actions.export' | translate }}"
      (click)="exportUserFunction()"
    ></p-button>
  </ng-container>
</selfai-platform-data-list-view>
