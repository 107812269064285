<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="p-float-label mt-4">
    <input pAutoFocus [autofocus]="true" class="w-full" formControlName="name" pInputText type="text" />
    <label for="float-input">{{ 'workflow-list.dialog.add-workflow.name' | translate }}</label>
  </div>
  <div class="p-float-label mt-5">
    <input class="w-full" formControlName="description" pInputText type="text" />
    <label for="float-input">{{ 'workflow-list.dialog.add-workflow.description' | translate }}</label>
  </div>

  <div class="mt-5">
    <button
      type="button"
      class="mr-3 p-button-secondary p-button-outlined"
      (click)="closeDialog()"
      (keydown.enter)="closeDialog()"
      pRipple
      pButton
      label="Close"
    ></button>

    <button
      type="submit"
      class="mr-3 p-button-success"
      pRipple
      pButton
      label="{{ 'workflow-list.dialog.add-workflow.create' | translate }}"
    ></button>
  </div>
</form>
